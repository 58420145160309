"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("../api");

var _DateCell = _interopRequireDefault(require("xqjr-plugin-common/AutoGenerate/TableCell/DateCell"));

var _SmallIcon = _interopRequireDefault(require("./SmallIcon"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "文件名",
  dataIndex: "name"
}, {
  title: "操作类型",
  dataIndex: "oprType"
}, {
  title: "操作人",
  dataIndex: "oprName"
}, {
  title: "更新时间",
  dataIndex: "gmtCreate"
}];
var _default = {
  name: "History",
  components: {
    smallIcon: _SmallIcon.default,
    DateCell: _DateCell.default
  },
  data: function data() {
    return {
      columns: columns,
      pagination: {
        pageSize: 10
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      repoId: "0",
      // 资料库ID
      parentId: "0",
      // 父级文件夹ID
      folderImg: "../images/folder_logo.png",
      fileImg: "../images/file_logo.png",
      dialogVisible: false,
      dialogVisibleFile: false,
      fileListShow: true,
      dialogType: "new",
      oprType: "",
      // 查询栏中根据操作类型查询
      oprTypeOptions: [{
        value: "create",
        label: "create"
      }, {
        value: "update",
        label: "update"
      }, {
        value: "delete",
        label: "delete"
      }, {
        value: "move",
        label: "move"
      }, {
        value: "recover",
        label: "recover"
      }],
      fileList: [] // 文件/文件夹列表

    };
  },
  methods: {
    // 接收父级参数
    toFilesList: function toFilesList(repoId, parentId) {
      this.repoId = repoId;
      this.parentId = parentId;
      this.reload();
    },
    // 加载文件夹修改历史列表
    selectFileslist: function selectFileslist() {
      var _this = this;

      var param = {};
      param["oprType"] = this.oprType;
      param["pageNum"] = this.currentPage;
      param["pageSize"] = this.pageSize;
      param["repoId"] = this.repoId;
      param["id"] = this.parentId;
      (0, _api.selectHistorylist)(param).then(function (res) {
        _this.fileList = res.data.entities;
        _this.total = res.data.entityCount;
      });
    },
    // 展示列表状态:true列表,false图标
    showStatus: function showStatus(flag) {
      this.fileListShow = flag;
    },
    // 刷新当前页面
    reload: function reload() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.oprType = "";
      this.selectFileslist();
    },
    onPageChange: function onPageChange(page) {
      this.currentPage = page;
      this.selectFileslist();
    },
    onPageSizeChange: function onPageSizeChange(current, size) {
      var _this2 = this;

      this.pageSize = size;
      this.pagination["pageSize"] = size;
      this.$nextTick(function () {
        _this2.onPageChange(1);
      });
    }
  }
};
exports.default = _default;