"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

var _api = require("../api");

var _SmallIcon = _interopRequireDefault(require("./SmallIcon"));

var _BigIcon = _interopRequireDefault(require("./BigIcon"));

var _antDesignVue = require("ant-design-vue");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "文件名",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "删除时间",
  dataIndex: "gmtModified"
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 160
}];
var _default = {
  name: "RecycleBin",
  components: {
    smallIcon: _SmallIcon.default,
    bigIcon: _BigIcon.default
  },
  data: function data() {
    return {
      columns: columns,
      pagination: {
        pageSize: 10
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      selectedRowKeys: [],
      repoId: "0",
      // 资料库ID(仅父子级传参用)
      fileId: "0",
      // 所属的文件夹ID
      folderImg: "../images/folder_logo.png",
      fileImg: "../images/file_logo.png",
      dialogVisible: false,
      dialogVisibleFile: false,
      fileListShow: true,
      dialogType: "new",
      fileName: "",
      // 查询栏中输入的资料库名称
      fileList: [] // 文件/文件夹列表

    };
  },
  computed: {
    selectionFlag: function selectionFlag() {
      return this.selectedRowKeys.length > 0;
    }
  },
  methods: {
    onPageChange: function onPageChange(page) {
      this.currentPage = page;
      this.selectFileslist();
    },
    onPageSizeChange: function onPageSizeChange(current, size) {
      var _this = this;

      this.pageSize = size;
      this.pagination["pageSize"] = size;
      this.$nextTick(function () {
        _this.onPageChange(1);
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 接收父级参数
    toFilesList: function toFilesList(repoId, fileId) {
      this.repoId = repoId;
      this.fileId = fileId;
      this.reload();
    },
    // 加载资料库下文件夹列表
    selectFileslist: function selectFileslist() {
      var _this2 = this;

      var param = {};
      param["name"] = this.fileName;
      param["pageNum"] = this.currentPage;
      param["pageSize"] = this.pageSize;
      param["repoId"] = this.repoId;
      param["fileId"] = this.fileId;
      (0, _api.selectRBFileslist)(param).then(function (res) {
        _this2.fileList = res.data.entities;
        _this2.total = res.data.entityCount;
      });
    },
    // 展示列表状态:true列表,false图标
    showStatus: function showStatus(flag) {
      this.fileListShow = flag;
    },
    // 刷新当前页面
    reload: function reload() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.fileName = "";
      this.selectFileslist();
    },
    // 删除文件
    delFile: function delFile(id) {
      var _this3 = this;

      (0, _api.delRBFile)([id]).then(function (response) {
        _antDesignVue.message.success("删除成功");

        _this3.reload(); // 刷新当前页面

      });
    },
    // 批量删除
    delBatch: function delBatch() {
      var _this4 = this;

      (0, _api.delRBFile)(this.selectedRowKeys).then(function (response) {
        _antDesignVue.message.success("删除成功");

        _this4.reload(); // 刷新当前页面

      });
    },
    // 清空回收站
    clear: function clear() {
      var _this5 = this;

      (0, _api.clearRB)(this.repoId).then(function (response) {
        _antDesignVue.message.success("清空成功");

        _this5.reload(); // 刷新当前页面

      });
    },
    // 还原文件
    restoreFile: function restoreFile(id) {
      var _this6 = this;

      (0, _api.restoreFile)([id]).then(function (response) {
        _antDesignVue.message.success("还原成功");

        _this6.reload(); // 刷新当前页面

      });
    },
    // 批量还原
    restoreBatch: function restoreBatch() {
      var _this7 = this;

      (0, _api.restoreFile)(this.selectedRowKeys).then(function (response) {
        _antDesignVue.message.success("还原成功");

        _this7.reload(); // 刷新当前页面

      });
    },
    // 选中的当前table行数据
    selectCurrentRow: function selectCurrentRow(val) {
      var _this8 = this;

      // 提示是否还原当前文件
      this.$confirm("确定要还原该文件?", val.name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _api.restoreFile)(val.id).then(function (response) {
          _antDesignVue.message.success("还原成功");

          _this8.reload(); // 刷新当前页面

        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;