var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-alert", {
        staticClass: "mb24",
        attrs: {
          message:
            "提示：请在工作日15:00前完成公对公转账，15:00之后银行大额转账需隔天到账。",
          banner: ""
        }
      }),
      _c("a-table", {
        attrs: { columns: _vm.columns, "data-source": _vm.data },
        scopedSlots: _vm._u([
          {
            key: "tags",
            fn: function(tags, record) {
              return _c("span", {}, [
                _c("div", [_vm._v("公司名称：" + _vm._s(record.companyName))]),
                _c("div", [_vm._v("工商注册号：" + _vm._s(record.tyshxydm))]),
                _c("div", [_vm._v("税务登记号：" + _vm._s(record.tyshxydm))]),
                _c("div", [_vm._v("组织机构代码：" + _vm._s(record.tyshxydm))])
              ])
            }
          },
          {
            key: "bank",
            fn: function(text, record) {
              return _c("span", {}, [
                _c("div", [
                  _vm._v("开户（网点）银行：" + _vm._s(record.khwdyh))
                ]),
                _c("div", [_vm._v("账户名称：" + _vm._s(record.zhmc))]),
                _c("div", [_vm._v("银行账号：" + _vm._s(record.yhzh))])
              ])
            }
          },
          {
            key: "action",
            fn: function(text, record) {
              return _c("span", {}, [
                _c("div", [
                  _vm._v(
                    "服务费率：" +
                      _vm._s(Number(record.serviceRate * 100).toFixed(1)) +
                      "%"
                  )
                ]),
                _c("div", [_vm._v("最大金额：" + _vm._s(record.singleMax))]),
                _c("div", [_vm._v("最小金额：" + _vm._s(record.singleMin))])
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }