"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.join.js");

var _api = require("./api");

var _Form = _interopRequireDefault(require("./Form.json"));

var _xqjrPluginCommon = require("xqjr-plugin-common");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
// ----------------------------------------------------- 构建表格配置，包括基本项、操作
var columns = _Form.default.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});

columns.push({
  width: 120,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new _xqjrPluginCommon.CurdMixin({
  retrieveReq: _api.retrieveReq,
  createReq: _api.createReq,
  detailReq: _api.detailReq,
  deleteReq: _api.deleteReq,
  updateReq: _api.updateReq,
  deleteBatchReq: _api.deleteBatchReq,
  RecordForm: _Form.default,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

var _default = {
  components: {},
  mixins: [mixin],
  data: function data() {
    return {
      scanVisible: false,
      loadingcode: false,
      timeDay: 7,
      FailureTime: "",
      taskId: null,
      postTypeOptions: [{
        value: 1,
        label: "劳务派遣"
      }, {
        value: 2,
        label: "外包"
      }, {
        value: 3,
        label: "社保代缴"
      }, {
        value: 4,
        label: "薪资代发"
      }],
      codeimg: "",
      refreshCode: false
    };
  },
  computed: {
    createParams: function createParams() {
      return {
        amount: 0
      };
    }
  },
  created: function created() {
    var _this = this;

    (0, _api.listTaskAll)().then(function (res) {
      if (res.data) {
        _this.postTypeOptions = res.data;

        if (_this.postTypeOptions.length > 0) {
          _this.taskId = _this.postTypeOptions[0].id;
        }
      }
    }).catch(function (err) {
      console.log(err);
      _this.loadingcode = false;
    });
  },
  mounted: function mounted() {},
  methods: {
    scanForm: function scanForm() {
      this.scanVisible = true;
      this.getCode();
    },
    getCode: function getCode() {
      var _this2 = this;

      if (!this.taskId) {
        return;
      }

      this.loadingcode = true;
      (0, _api.getCode)({
        taskId: this.taskId
      }).then(function (res) {
        _this2.loadingcode = false;

        if (res.data) {
          _this2.codeimg = res.data.qrCodeUrl;
          _this2.timeDay = res.data.validDays;
          _this2.taskId = res.data.taskId;
          var date = new Date(res.data.expireDate); // 时间戳转date

          _this2.FailureTime = _this2.moment(date).format("YYYY-MM-DD HH:MM");

          if (res.data.flowId === null) {
            _this2.muban = undefined;
          } else {
            _this2.muban = res.data.flowId;
            var has = false;

            for (var m = 0; m < _this2.SMMubanList.length; m++) {
              if (_this2.muban === _this2.SMMubanList[m].value) {
                has = true;
                break;
              }
            }

            if (!has) {
              _this2.muban = undefined;
            }
          }
        } else {
          var d = new Date();

          var newTime = _this2.afterTime(_this2.moment(d, "YYYY-MM-DD"), _this2.timeDay);

          _this2.FailureTime = newTime + " " + d.getHours() + ":" + d.getMinutes();
          _this2.refreshCode = true;
        }
      });
    },
    creatCode: function creatCode() {
      var _this3 = this;

      this.loadingcode = true;
      var par = {
        validDays: this.timeDay,
        // 有效天数
        taskId: this.taskId,
        // 任务id
        expireDate: new Date(this.FailureTime + ":00").getTime() // 过期时间

      };
      (0, _api.creatCode)(par).then(function (res) {
        _this3.loadingcode = false;

        if (res.data) {
          _this3.codeimg = res.data.qrCodeUrl;
          _this3.timeDay = res.data.validDays;
          _this3.taskId = res.data.taskId;
          var date = new Date(res.data.expireDate); // 时间戳转date

          _this3.FailureTime = _this3.moment(date).format("YYYY-MM-DD HH:MM");
          _this3.refreshCode = false;

          if (!_this3.codeimg) {
            _this3.refreshCode = true;
          }
        } else {
          _this3.loadingcode = false;

          _this3.$notification["success"]({
            message: "success",
            description: "二维码创建失败"
          });
        }
      }).catch(function (err) {
        console.log(err);
        _this3.loadingcode = false;
      });
    },
    downloadCod: function downloadCod() {
      window.open(this.codeimg);
    },
    postTypeSaoMaChange: function postTypeSaoMaChange(value) {
      this.taskId = value;
      this.refreshCode = true;
      console.log(this.taskId);
    },
    setAllContent: function setAllContent(val) {
      this.timeDay = val;
      var d = new Date();
      var newTime = this.afterTime(this.moment(d, "YYYY-MM-DD"), this.timeDay);
      this.FailureTime = newTime + " " + d.getHours() + ":" + d.getMinutes();
      this.refreshCode = true;
    },
    afterTime: function afterTime(now, num) {
      var d = new Date(now);
      d.setDate(d.getDate() + num); // d.setMonth(d.getMonth() + num);

      var yy1 = d.getFullYear();
      var mm1 = d.getMonth() + 1; // 因为getMonth（）返回值是 0（一月） 到 11（十二月） 之间的一个整数。所以要给其加1

      var dd1 = d.getDate();

      if (mm1 < 10) {
        mm1 = "0" + mm1;
      }

      if (dd1 < 10) {
        dd1 = "0" + dd1;
      }

      return yy1 + "-" + mm1 + "-" + dd1;
    },
    scanCancel: function scanCancel() {
      this.scanVisible = false;
    },
    sendContract: function sendContract(record) {
      var _this4 = this;

      (0, _api.contractAgain)(record.id).then(function (res) {
        _this4.$notification["success"]({
          message: "Success",
          description: "重新发起合同成功"
        });

        setTimeout(function () {
          location.reload();
        }, 2000);
      });
    },
    goDetail: function goDetail(record) {
      this.$router.push({
        path: "/contract/detail?type=freelance&title=自由职业者&id=" + record.id
      });
    },
    importExcel: function importExcel() {
      this.$router.push({
        path: "/contract/upload"
      });
    },
    exportExcel: function exportExcel() {
      var newURLArr = [];

      for (var key in this.searchArguments) {
        if (this.searchArguments[key] && key !== "pageNo" && key !== "pageSize") {
          newURLArr.push("".concat(key, "=").concat(this.searchArguments[key]));
        } else if (key === "isTaxNo") {
          newURLArr.push("isTaxNo=0");
        }
      }

      window.open("/youhuo/api/v1/freelances/export?" + newURLArr.join("&"));
    }
  }
};
exports.default = _default;