"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/test-e-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.sort.js");

var _api = require("./api");

var _Form = _interopRequireDefault(require("./Form.json"));

var _xqjrPluginCommon = require("xqjr-plugin-common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
// ----------------------------------------------------- 构建表格配置，包括基本项、操作
var columns = _Form.default.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    // fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
}); // columns.push({
//   width: 120,
//   title: "操作",
//   dataIndex: "crudAction",
//   scopedSlots: { customRender: "crudAction" },
//   type: "action",
//   fixed: "right"
// });
// ----------------------------------------------------- 生成 mixin


var mixin = new _xqjrPluginCommon.CurdMixin({
  retrieveReq: _api.retrieveReq,
  createReq: _api.createReq,
  deleteReq: _api.deleteReq,
  updateReq: _api.updateReq,
  deleteBatchReq: _api.deleteBatchReq,
  RecordForm: _Form.default,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

var _default = {
  components: {},
  mixins: [mixin],
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;